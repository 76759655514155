var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-road-variant ")]),_c('span',{staticClass:"pl-2"},[_vm._v("Routes")]),_c('v-spacer'),_c('v-sheet',{staticClass:"text-center mx-auto",attrs:{"elevation":"3","rounded":"lg"}},[_c('div',{staticClass:"mx-4"},[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.routes_type),callback:function ($$v) {_vm.routes_type=$$v},expression:"routes_type"}},[_c('v-radio',{attrs:{"label":"All routes","value":"all"}}),_c('v-radio',{attrs:{"label":"Morning routes","value":"morning"}}),_c('v-radio',{attrs:{"label":"Afternoon routes","value":"afternoon"}})],1)],1)]),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" Create ")])]}}])},[_c('v-list',_vm._l((_vm.menu_items),function(item,index){return _c('v-list-item',{key:index,attrs:{"value":index},on:{"click":function($event){return _vm.createRoute(item.title)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1),_c('activation-tool-tip',{attrs:{"model":"routes"}})],1),_c('v-data-table',{attrs:{"item-key":"name","loading":_vm.isLoading,"loading-text":"Loading... Please wait","headers":_vm.headers,"items":_vm.displayedRoutes,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(_vm._s(_vm._f("moment")(item.created_at,"LL")))]),_vm._v(" - "),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm._f("moment")(item.created_at,"LT")))])]}},{key:"item.is_morning",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getTimeColor(item.is_morning),"dark":""}},[_vm._v(" "+_vm._s(_vm.getTime(item.is_morning))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"small":""},on:{"click":function($event){return _vm.viewRoute(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])]}}],null,true)},[_c('span',[_vm._v("View route")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editRoute(item, 'duplicate-new')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-content-duplicate ")])]}}],null,true)},[_c('span',[_vm._v("Duplicate as new")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.is_morning)?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"small":""},on:{"click":function($event){return _vm.editRoute(item, 'duplicate-reverse')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-moon-waning-crescent ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Duplicate as afternoon")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!item.is_morning)?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"small":""},on:{"click":function($event){return _vm.editRoute(item, 'duplicate-reverse')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-white-balance-sunny ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Duplicate as morning")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"small":""},on:{"click":function($event){_vm.deleteRoute(item, _vm.displayedRoutes.indexOf(item))}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Delete route")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }